<template>
    <v-container>
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2"> mdi-flag</v-icon
                >Tabela de Emolumentos
            </v-card-text>
        </v-card>
        <v-card>
            <v-layout
                class="ma-3"
                row
                wrap
                v-for="item in lista_ufs"
                v-bind:key="item"
            >
                <v-flex xs12 md3 class="pa-4">
                    <v-card
                        class="ma-8 pa-10"
                        outlined
                        tile
                        color="transparent"
                        xs1
                    >
                        <v-row
                            :align="alignment"
                            :justify="justify"
                            style="height: 40px; width=200px"
                        >
                            <a
                                :href="item.link1"
                                onMouseOver="this.style.color='blue'"
                                onMouseOut="this.style.color='black'"
                                style="
                                    text-decoration: none;
                                    color: black;
                                    white-space: nowrap;
                                "
                            >
                                {{ item.nome1 }}
                            </a>
                        </v-row>

                        <v-row :align="alignment" :justify="justify">
                            <a :href="item.link1">
                                <v-card shaped class="ma-3 pa-3">
                                    <img
                                        :src="item.img1"
                                        height="200%"
                                        width="200%"
                                        class="mr-6"
                                        style="border: 1px solid #d3d3d3"
                                    />
                                </v-card>
                            </a>
                        </v-row>
                    </v-card>
                </v-flex>

                <v-flex xs12 md3 class="pa-4">
                    <v-card
                        class="ma-8 pa-10"
                        outlined
                        tile
                        color="transparent"
                    >
                        <v-row
                            :align="alignment"
                            :justify="justify"
                            style="height: 40px; width=200px"
                        >
                            <a
                                :href="item.link2"
                                onMouseOver="this.style.color='blue'"
                                onMouseOut="this.style.color='black'"
                                style="
                                    text-decoration: none;
                                    color: black;
                                    white-space: nowrap;
                                "
                            >
                                {{ item.nome2 }}
                            </a>
                        </v-row>
                        <v-row :align="alignment" :justify="justify">
                            <a :href="item.link2">
                                <v-card shaped class="ma-3 pa-3">
                                    <img
                                        :src="item.img2"
                                        height="200%"
                                        width="200%"
                                        style="border: 1px solid #d3d3d3"
                                        class="mr-6"
                                    />
                                </v-card>
                            </a>
                        </v-row>
                    </v-card>
                </v-flex>

                <v-flex xs12 md3 class="pa-4">
                    <v-card
                        class="ma-8 pa-10"
                        outlined
                        tile
                        color="transparent"
                    >
                        <v-row
                            :align="alignment"
                            :justify="justify"
                            style="height: 40px; width=200px"
                        >
                            <a
                                :href="item.link3"
                                onMouseOver="this.style.color='blue'"
                                onMouseOut="this.style.color='black'"
                                style="
                                    text-decoration: none;
                                    color: black;
                                    white-space: nowrap;
                                "
                            >
                                {{ item.nome3 }}
                            </a>
                        </v-row>
                        <v-row :align="alignment" :justify="justify">
                            <a :href="item.link3">
                                <v-card shaped class="ma-3 pa-3">
                                    <img
                                        :src="item.img3"
                                        height="200%"
                                        width="200%"
                                        style="border: 1px solid #d3d3d3"
                                        class="mr-6"
                                    />
                                </v-card>
                            </a>
                        </v-row>
                    </v-card>
                </v-flex>

                <v-flex xs12 md3 class="pa-4">
                    <v-card
                        class="ma-8 pa-10"
                        outlined
                        tile
                        color="transparent"
                    >
                        <v-row
                            :align="alignment"
                            :justify="justify"
                            style="height: 40px; width=200px"
                        >
                            <a
                                :href="item.link4"
                                onMouseOver="this.style.color='blue'"
                                onMouseOut="this.style.color='black'"
                                style="
                                    text-decoration: none;
                                    color: black;
                                    white-space: nowrap;
                                "
                            >
                                {{ item.nome4 }}
                            </a>
                        </v-row>
                        <v-row :align="alignment" :justify="justify">
                            <a :href="item.link4">
                                <v-card shaped class="ma-3 pa-3">
                                    <img
                                        :src="item.img4"
                                        height="200%"
                                        width="200%"
                                        style="border: 1px solid #d3d3d3"
                                    />
                                </v-card>
                            </a>
                        </v-row>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        alignment: 'center',
        justify: 'center',
        lista_ufs: [
            {
                nome1: 'São Paulo (SP)',
                img1: require('../assets/flags/flag-sp.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/SP.pdf',
                nome2: 'Rio de Janeiro (RJ)',
                img2: require('../assets/flags/flag-rj.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/RJ.pdf',
                nome3: 'Minas Gerais (MG)',
                img3: require('../assets/flags/flag-mg.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/MG.pdf',
                nome4: 'Tocantins (TO)',
                img4: require('../assets/flags/flag-to.png'),
                link4:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/TO.pdf'
            },
            {
                nome1: 'Acre (AC)',
                img1: require('../assets/flags/flag-ac.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/AC.pdf',
                nome2: 'Alagoas (AL)',
                img2: require('../assets/flags/flag-al.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/AL.pdf',
                nome3: 'Amazonas (AM)',
                img3: require('../assets/flags/flag-am.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/AM.pdf',
                nome4: 'Rio Grande do Sul (RS)',
                img4: require('../assets/flags/flag-rs.png'),
                link4:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/RS.pdf'
            },
            {
                nome1: 'Amapá (AP)',
                img1: require('../assets/flags/flag-ap.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/AP.pdf',
                nome2: 'Bahia (BA)',
                img2: require('../assets/flags/flag-ba.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/BA.pdf',
                nome3: 'Ceará (CE)',
                img3: require('../assets/flags/flag-ce.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/CE.pdf',
                nome4: 'Roraima (RR)',
                img4: require('../assets/flags/flag-rr.png'),
                link4: 'http://diario.tjrr.jus.br/dpj/dpj-20180119.pdf'
            },
            {
                nome1: 'Distrito Federal (DF)',
                img1: require('../assets/flags/flag-df.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/DF.pdf',
                nome2: 'Espírito Santo (ES)',
                img2: require('../assets/flags/flag-es.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/ES.pdf',
                nome3: 'Goiás (GO)',
                img3: require('../assets/flags/flag-go.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/GO.pdf',
                nome4: 'Rondônia (RO)',
                img4: require('../assets/flags/flag-ro.png'),
                link4:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/RO.pdf'
            },
            {
                nome1: 'Maranhão (MA)',
                img1: require('../assets/flags/flag-ma.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/MA.pdf',
                nome2: 'Mato Grosso do Sul (MS)',
                img2: require('../assets/flags/flag-ms.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/MS.pdf',
                nome3: 'Mato Grosso (MT)',
                img3: require('../assets/flags/flag-mt.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/MT.pdf',
                nome4: 'Sergipe (SE)',
                img4: require('../assets/flags/flag-se.png'),
                link4:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/SE.pdf'
            },
            {
                nome1: 'Pará (PA)',
                img1: require('../assets/flags/flag-pa.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/PA.pdf',
                nome2: 'Paraíba (PB)',
                img2: require('../assets/flags/flag-pb.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/PB.pdf',
                nome3: 'Pernambuco (PE)',
                img3: require('../assets/flags/flag-pe.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/PE.pdf',
                nome4: 'Santa Catarina (SC)',
                img4: require('../assets/flags/flag-sc.png'),
                link4:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/SC.pdf'
            },
            {
                nome1: 'Piauí (PI)',
                img1: require('../assets/flags/flag-pi.png'),
                link1:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/PI.pdf',
                nome2: 'Paraná (PR)',
                img2: require('../assets/flags/flag-pr.png'),
                link2:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/PR.pdf',
                nome3: 'Rio Grande do Norte (RN)',
                img3: require('../assets/flags/flag-rn.png'),
                link3:
                    'http://www.protestodetitulos.org.br/arquivos/tabelas/emolumentos/RN.pdf'
            }
        ]
    })
};
</script>
