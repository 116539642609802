import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c(
        VCard,
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            VCardText,
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c(VIcon, { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-flag")
              ]),
              _vm._v("Tabela de Emolumentos ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        _vm._l(_vm.lista_ufs, function(item) {
          return _c(
            VLayout,
            { key: item, staticClass: "ma-3", attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { staticClass: "pa-4", attrs: { xs12: "", md3: "" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "ma-8 pa-10",
                      attrs: {
                        outlined: "",
                        tile: "",
                        color: "transparent",
                        xs1: ""
                      }
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: { height: "40px" },
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "black",
                                "white-space": "nowrap"
                              },
                              attrs: {
                                href: item.link1,
                                onMouseOver: "this.style.color='blue'",
                                onMouseOut: "this.style.color='black'"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.nome1) + " ")]
                          )
                        ]
                      ),
                      _c(
                        VRow,
                        {
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: item.link1 } },
                            [
                              _c(
                                VCard,
                                {
                                  staticClass: "ma-3 pa-3",
                                  attrs: { shaped: "" }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-6",
                                    staticStyle: {
                                      border: "1px solid #d3d3d3"
                                    },
                                    attrs: {
                                      src: item.img1,
                                      height: "200%",
                                      width: "200%"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { staticClass: "pa-4", attrs: { xs12: "", md3: "" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "ma-8 pa-10",
                      attrs: { outlined: "", tile: "", color: "transparent" }
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: { height: "40px" },
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "black",
                                "white-space": "nowrap"
                              },
                              attrs: {
                                href: item.link2,
                                onMouseOver: "this.style.color='blue'",
                                onMouseOut: "this.style.color='black'"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.nome2) + " ")]
                          )
                        ]
                      ),
                      _c(
                        VRow,
                        {
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: item.link2 } },
                            [
                              _c(
                                VCard,
                                {
                                  staticClass: "ma-3 pa-3",
                                  attrs: { shaped: "" }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-6",
                                    staticStyle: {
                                      border: "1px solid #d3d3d3"
                                    },
                                    attrs: {
                                      src: item.img2,
                                      height: "200%",
                                      width: "200%"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { staticClass: "pa-4", attrs: { xs12: "", md3: "" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "ma-8 pa-10",
                      attrs: { outlined: "", tile: "", color: "transparent" }
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: { height: "40px" },
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "black",
                                "white-space": "nowrap"
                              },
                              attrs: {
                                href: item.link3,
                                onMouseOver: "this.style.color='blue'",
                                onMouseOut: "this.style.color='black'"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.nome3) + " ")]
                          )
                        ]
                      ),
                      _c(
                        VRow,
                        {
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: item.link3 } },
                            [
                              _c(
                                VCard,
                                {
                                  staticClass: "ma-3 pa-3",
                                  attrs: { shaped: "" }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-6",
                                    staticStyle: {
                                      border: "1px solid #d3d3d3"
                                    },
                                    attrs: {
                                      src: item.img3,
                                      height: "200%",
                                      width: "200%"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { staticClass: "pa-4", attrs: { xs12: "", md3: "" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "ma-8 pa-10",
                      attrs: { outlined: "", tile: "", color: "transparent" }
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: { height: "40px" },
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "black",
                                "white-space": "nowrap"
                              },
                              attrs: {
                                href: item.link4,
                                onMouseOver: "this.style.color='blue'",
                                onMouseOut: "this.style.color='black'"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.nome4) + " ")]
                          )
                        ]
                      ),
                      _c(
                        VRow,
                        {
                          attrs: { align: _vm.alignment, justify: _vm.justify }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: item.link4 } },
                            [
                              _c(
                                VCard,
                                {
                                  staticClass: "ma-3 pa-3",
                                  attrs: { shaped: "" }
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      border: "1px solid #d3d3d3"
                                    },
                                    attrs: {
                                      src: item.img4,
                                      height: "200%",
                                      width: "200%"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }